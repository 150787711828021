import { api } from 'api';
import { isCipherAvailable } from 'api/CertificateService/CipherModule/CipherService';
import { CertificateStatus, KeyType, UserKeystoreType } from 'api/CertificateService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { isVisibleAction } from 'pages/Payments/utils';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { switchSignApplications } from 'pages/utils/SignOrders/utils';
import { checkHasConfirmationFactor, toUniqueArray } from 'pages/utils/utils';

import { KepPinTokenConfirmationPaymentsModal } from './KepPinTokenConfirmationPaymentsModal';

export const multiplyPinTokenConfirmationPayment = async (
  orders: DemandResponse[],
): Promise<void> => {
  const filteredOrders = orders.filter(order =>
    isVisibleAction(order, OrderActionType.CONFIRM_AF_PIN),
  );

  const customerIds = toUniqueArray(orders.map(item => item.base.customerId));

  await pinTokenConfirmationPayments(
    filteredOrders.map(({ id }) => id),
    customerIds,
    orders.length,
  );
};

export const pinTokenConfirmationPayments = async (
  ids: number[],
  customerIds: number[],
  orderCount?: number,
): Promise<void> => {
  const hasAccessToConfirm = await checkHasConfirmationFactor(ids);

  if (!hasAccessToConfirm) {
    return;
  }

  const certificates = await api.certificate.getCertificates(customerIds, true, {
    statuses: CertificateStatus.Active,
    keyType: KeyType.TOKEN,
  });

  const filteredOnlyTokenCertificates = certificates.filter(
    cert => cert.userKeystoreType === UserKeystoreType.HardWired,
  );

  const result = await switchSignApplications({
    certificates: filteredOnlyTokenCertificates,
    warningMessage: 'front.confirm-modal.switch-login.label',
  });

  if (!result) {
    return;
  }

  const isConnect = await isCipherAvailable();

  if (isConnect) {
    return await confirmModal(KepPinTokenConfirmationPaymentsModal, {
      ids,
      certificates,
      orderCount,
    });
  }

  await confirmModal(NoConnectionModal, {
    description: 'front.certificates.error-modal-cipher-text.label',
  });
};
